import { Suspense, useEffect, useReducer } from 'react'

import { useDebounce } from '@uidotdev/usehooks'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'sonner'

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'

import { useWidgetActions, useWidgetFilters } from '../store'

import FilterLastUpdated from './filter-last-updated'
import { PeriodSelect } from './period-select'

import type { TriggerAlertFilters } from '../../types'

type FilterState = Required<Pick<TriggerAlertFilters, 'limit'>> &
	Pick<TriggerAlertFilters, 'start_time' | 'end_time'>

type FilterAction =
	| { type: 'SET_LIMIT'; payload: number }
	| { type: 'SET_START_TIME'; payload: string | undefined }
	| { type: 'SET_END_TIME'; payload: string | undefined }

const MIN_LIMIT = 100
const MAX_LIMIT = 3000

function filterReducer(state: FilterState, action: FilterAction): FilterState {
	switch (action.type) {
		case 'SET_LIMIT':
			return {
				...state,
				// limit must be between MIN_LIMIT and MAX_LIMIT
				limit: Math.min(Math.max(action.payload, MIN_LIMIT), MAX_LIMIT),
			}
		case 'SET_START_TIME':
			return { ...state, start_time: action.payload }
		case 'SET_END_TIME':
			return { ...state, end_time: action.payload }
		default:
			return state
	}
}

export const AlertsFilters = () => {
	const posthog = usePostHog()

	const filters = useWidgetFilters()
	const { setFilters } = useWidgetActions()

	const [state, dispatch] = useReducer(filterReducer, {
		limit: filters?.limit ?? MIN_LIMIT,
		start_time: filters?.start_time,
		end_time: filters?.end_time,
	})

	const debouncedState = useDebounce(state, 500)

	useEffect(() => {
		setFilters(debouncedState)
		if (debouncedState.limit !== state.limit) {
			toast.info(`Limit set to ${debouncedState.limit}`)
		}
	}, [debouncedState])

	return (
		<div className="mb-4 flex w-full flex-col items-end justify-between gap-4 sm:flex-row">
			<div className="flex w-full flex-col items-center justify-start gap-2 sm:flex-row">
				<div className="flex w-full flex-col gap-1 sm:w-auto">
					<p className="text-sm font-medium">Limit of entries</p>
					<Select
						value={state.limit.toString()}
						onValueChange={(value) => {
							posthog.capture('graylog_alerts_filters_limit', {
								limit: Number(value),
							})

							dispatch({ type: 'SET_LIMIT', payload: Number(value) })
						}}
					>
						<SelectTrigger className="min-w-[200px]">
							<SelectValue placeholder="Limit" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="100">100</SelectItem>
							<SelectItem value="500">500</SelectItem>
							<SelectItem value="1000">1000</SelectItem>
							<SelectItem value="3000">3000</SelectItem>
						</SelectContent>
					</Select>
				</div>

				<div className="flex w-full flex-col gap-1 sm:w-auto">
					<p className="text-sm font-medium">Period</p>
					<PeriodSelect
						start_time={state.start_time ?? ''}
						end_time={state.end_time ?? ''}
						onCustomPeriodChange={(start_time, end_time) => {
							posthog.capture('graylog_alerts_filters_period', {
								start_time,
								end_time,
							})

							dispatch({ type: 'SET_START_TIME', payload: start_time })
							dispatch({ type: 'SET_END_TIME', payload: end_time })
						}}
					/>
				</div>
			</div>

			<Suspense fallback={<Skeleton className="h-12 w-[300px]" />}>
				<div className="flex flex-shrink-0 flex-grow-0 items-start justify-start">
					<FilterLastUpdated />
				</div>
			</Suspense>
		</div>
	)
}

export default AlertsFilters
