import { useState } from 'react'

import { SchemaForm } from '@/components/forms/schema-form'

import { useCreateIntegration, useEditIntegration } from '../mutations'

import type { NewIntegrationFormData } from '../types'
import type {
	FormSchema,
	InputProperty,
	InputSchema,
} from '@/components/forms/schema-form/types'

type NewIntegrationFormProps = {
	integration_id: string
	input_schema: InputSchema
	initialValues?: Record<string, any>
	onSuccess?: () => void
}

export const NewIntegrationForm = ({
	input_schema,
	integration_id,
	initialValues,
	onSuccess,
}: NewIntegrationFormProps) => {
	const [isLoading, setIsLoading] = useState(false)

	const { mutate: createIntegration } = useCreateIntegration()
	const { mutate: editIntegration } = useEditIntegration()

	const isEdit = !!initialValues

	const nameFieldSchema: InputProperty = {
		type: 'string',
		title: 'Name',
		description: 'Give a name to your integration',
		default: 'My New Integration',
	}

	const formSchema: FormSchema = {
		input_schema: {
			...input_schema,
			properties: {
				name: isEdit ? initialValues?.name : nameFieldSchema,
				...input_schema.properties,
			},
		},
		output_schema: undefined,
	}

	const handleSubmit = (data: Record<string, any>) => {
		setIsLoading(true)

		// Extract the name and the rest of the data
		const { name, ...connection_config } = data

		const newIntegration: NewIntegrationFormData = {
			name,
			connection_config,
			definition_id: integration_id,
			enabled: true,
		}

		if (!isEdit) {
			createIntegration(newIntegration, {
				onSettled: () => {
					setIsLoading(false)
				},
			})
		} else {
			editIntegration(
				{ integration_id, integration_data: newIntegration },
				{
					onSettled: () => {
						setIsLoading(false)
					},
				},
			)
		}

		onSuccess?.()
	}

	return (
		<SchemaForm
			formSchema={formSchema}
			onSubmit={handleSubmit}
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			buttonLabel="Save"
		/>
	)
}

export default NewIntegrationForm
