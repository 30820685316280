import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { parseRedirect } from '@/utils'

import { Subtitle, Title } from '@/features/auth/components/card'
import { OTPForm } from '@/features/auth/components/forms/otp'
import { usePreloadRoute } from '@/hooks/use-preload-route'

export const Route = createFileRoute('/_auth/totp')({
	component: LoginRoute,
})

function LoginRoute() {
	usePreloadRoute({ to: '/forgot-password' })

	const navigate = useNavigate()

	// Get the redirect from the search params
	const { redirect } = Route.useSearch({
		select: (search) => ({
			redirect: search.redirect,
		}),
	})

	const handleRedirect = (isSignedIn?: boolean) => {
		if (isSignedIn) {
			if (redirect) {
				const { pathname, search: parsedSearchObject } = parseRedirect(redirect)

				// Navigate to the redirect URL
				navigate({
					to: pathname,
					search: {
						...parsedSearchObject,
					},
				})
			} else {
				// If no redirect is provided, we redirect the user to the dashboard
				navigate({ to: '/graylog' })
			}
		}
	}

	return (
		<>
			<title>Time-Based One-Time Password | BitLyft Air®</title>

			<Title>TOTP Code</Title>
			<Subtitle>Enter your Timed One Time Password (TOTP)</Subtitle>

			<OTPForm onSuccess={handleRedirect} />
		</>
	)
}
