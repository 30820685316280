import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { getDownloadLink, getReports } from './api'

export const mdrKeys = {
	all: ['mdr'] as const,
	reports: (tenant: string) => [...mdrKeys.all, tenant, 'no-cache'] as const,
	downloadLink: (tenant: string, report_name: string) =>
		[...mdrKeys.all, tenant, report_name, 'no-cache'] as const,
}

export const mdrQueries = {
	reports: (tenant: string) =>
		queryOptions({
			queryKey: mdrKeys.reports(tenant),
			queryFn: () => getReports(tenant),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			// The list is generated once a month / quarter, so we cache for 1 hour
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
		}),
	downloadLink: (tenant: string, report_name: string) =>
		queryOptions({
			queryKey: mdrKeys.downloadLink(tenant, report_name),
			queryFn: () => getDownloadLink(tenant, report_name),
			retry: defaultQueryRetry,
			gcTime: time(15, 'm'),
			// S3 links are valid for 1h, so we cache for 30m to be safe
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
		}),
}

/**
 * Custom hook to fetch Report
 */
export const useGetMDRReports = () => {
	const currentTenant = useCurrentTenant()

	return useSuspenseQuery(mdrQueries.reports(currentTenant))
}

/**
 * Custom hook to fetch Download Link
 * @param {string} report_name - The report name
 */
export const useGetMDRDownloadLink = (report_name: string) => {
	const currentTenant = useCurrentTenant()

	return useSuspenseQuery(mdrQueries.downloadLink(currentTenant, report_name))
}
