import { Suspense, useState } from 'react'

import { ErrorBoundary } from '@sentry/react'
import { usePostHog } from 'posthog-js/react'
import { toast } from 'sonner'

import { sentry } from '@/lib/sentry'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { SchemaForm } from '@/components/forms/schema-form'
import { Skeleton } from '@/components/ui/skeleton'

import { useRunAction } from '../mutations'
import { useActionDetails } from '../queries'

import type { FormProps } from '../types'
import type { FormSchema } from '@/components/forms/schema-form/types'
import type { FieldValues } from 'react-hook-form'

export const PanelForm = ({ module, handleClose }: FormProps) => {
	const posthog = usePostHog()

	const { data, isLoading: isLoadingData } = useActionDetails(
		module?.action_id as string,
	)

	// When submitting, we want to show a loading state
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { mutate: runAsyncAction } = useRunAction()

	const formSchema: FormSchema = {
		input_schema: data?.input_schema,
		output_schema: data?.output_schema,
	}

	const onSubmit = async (data: FieldValues) => {
		const module_data = {
			action_name: module?.action_id as string,
			action_input: data,
		}

		runAsyncAction(module_data, {
			onSuccess: () => {
				posthog.capture('action_run', module_data)
			},
			onSettled: () => {
				handleClose()
				setIsLoading(false)
			},
			onError: (error) => {
				toast.error('Error running action')

				sentry.captureException(error, {
					extra: {
						module_data,
					},
				})

				setIsLoading(false)
			},
		})
	}

	if (!data || isLoadingData) return <PanelFormSkeleton />

	return (
		<div className="my-6 w-full">
			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<PanelFormSkeleton />}>
					<SchemaForm
						formSchema={formSchema}
						onSubmit={onSubmit}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
					/>
				</Suspense>
			</ErrorBoundary>
		</div>
	)
}

const PanelFormSkeleton = () => {
	return (
		<div className="my-6 w-full">
			<div className="flex w-full flex-col items-start justify-start gap-6">
				<Skeleton className="h-12 w-full bg-foreground" />
				<Skeleton className="h-12 w-full bg-foreground" />
				<Skeleton className="h-12 w-full bg-foreground" />
			</div>
		</div>
	)
}

export default PanelForm
