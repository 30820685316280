import { useEffect } from 'react'

import { useLocation } from '@tanstack/react-router'

import { usePostHog } from 'posthog-js/react'

export default function PostHogPageView() {
	const location = useLocation()

	const posthog = usePostHog()

	// Track pageviews
	useEffect(() => {
		if (posthog) {
			posthog.capture('$pageview', {
				$current_url: location.href,
			})
		}
	}, [location, posthog])

	return null
}
