import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { mdrQueries } from '@/features/mdr/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import QueryBoundary from '@/components/common/query-boundary'
import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import TableSkeleton from '@/components/skeletons/table-skeleton'

import type { XiorError } from 'xior'

const ReportList = lazy(() => import('@/features/mdr/components/report-list'))

export const Route = createFileRoute('/_dashboard/mdr/executive-reports')({
	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		// Prefetch the reports for the tenant
		queryClient.prefetchQuery(mdrQueries.reports(tenant))
	},

	component: RouteComponent,
	pendingComponent: PendingComponent,
	errorComponent: ErrorComponent,
})

function RouteComponent() {
	return (
		<div className="flex flex-col gap-4">
			<QueryBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<TableSkeleton rows={4} />}>
					<ReportList />
				</Suspense>
			</QueryBoundary>
		</div>
	)
}

function PendingComponent() {
	return <TableSkeleton rows={4} />
}

function ErrorComponent({ error }: { error: Error }) {
	const err = error as XiorError

	const { reset } = useErrorBoundary()

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
