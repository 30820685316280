import { api } from '@/lib/api'

import type { MDRDownloadLink, MDRReports } from './types'

/**
 * Get the reports for a tenant
 * @param {string} tenant - The tenant ID
 * @returns {Promise<string[]>} The reports
 */
export const getReports = async (tenant: string): Promise<string[]> => {
	if (!tenant) return []

	const { data } = await api.get<MDRReports>(`/api/tenants/${tenant}/reports`)

	// If the response is an array of files, return the files
	if ('files' in data) {
		return data.files
	}
	// Otherwise, return an empty array
	else {
		return []
	}
}

/**
 * Get the download link for a report
 * @param {string} tenant - The tenant ID
 * @param {string} report_name - The report name
 * @returns {Promise<string>} The download link
 */
export const getDownloadLink = async (
	tenant: string,
	report_name: string,
): Promise<string> => {
	if (!tenant || !report_name) return ''

	const { data } = await api.get<MDRDownloadLink>(
		`/api/tenants/${tenant}/reports/${report_name}`,
	)

	// If the response is a download link, return the download link
	if ('download_link' in data) {
		return data.download_link
	}
	// Otherwise, return an empty string
	else {
		return ''
	}
}
