import { useMemo, useState } from 'react'

import { cn } from '@/utils'
import { Check, ChevronsUpDown } from 'lucide-react'

import { useGraylogEvents } from '@/features/graylog/queries'

import { Button } from '@/components/ui/button'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'

type EventListProps = {
	handleSelectEvent: (value: string) => void
}

export const EventList = ({ handleSelectEvent }: EventListProps) => {
	const [open, setOpen] = useState(false)
	const [value, setValue] = useState('')

	const {
		data: {
			action_output: { events },
		},
	} = useGraylogEvents()

	const alertsOptions = useMemo(() => {
		if (!events) return []

		return events.map((event) => ({
			value: event.title,
			label: event.title,
			id: event.id,
		}))
	}, [events])

	const onSelectEvent = (event: string) => {
		setValue(event)
		setOpen(false)

		handleSelectEvent(event)
	}

	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<p className="font-bold uppercase text-muted-foreground">
				Attach to a new Event Definition
			</p>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className="w-full justify-between"
						data-posthog-event="graylog_create_automation"
					>
						{value
							? alertsOptions?.find((option) => option.value === value)?.label
							: `Select event...`}
						<ChevronsUpDown className="ml-2 h-4 w-4 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-full p-0" align="start">
					<Command>
						<CommandInput placeholder={`Search event...`} />
						<CommandList>
							<CommandEmpty>No event found.</CommandEmpty>
							<CommandGroup>
								{alertsOptions?.map((option) => (
									<CommandItem
										key={option.id}
										value={option.value}
										onSelect={onSelectEvent}
									>
										<Check
											className={cn(
												'mr-2 h-4 w-4',
												value === option.value ? 'opacity-100' : 'opacity-0',
											)}
										/>
										{option.label}
									</CommandItem>
								))}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	)
}
