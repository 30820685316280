import { QueryErrorResetBoundary } from '@tanstack/react-query'

import { ErrorBoundary } from '@sentry/react'

import { useCurrentTenant } from '@/features/user/store'

import type { FallbackRender } from '@sentry/react'
import type { PropsWithChildren } from 'react'

export type QueryBoundaryProps = PropsWithChildren & {
	fallback: FallbackRender
}

export const QueryBoundary = ({ children, fallback }: QueryBoundaryProps) => {
	const tenant = useCurrentTenant()

	return (
		// Add a key to the QueryErrorResetBoundary to force a re-render when the tenant changes
		<QueryErrorResetBoundary key={tenant}>
			{({ reset }) => (
				<ErrorBoundary onReset={reset} fallback={fallback}>
					{children}
				</ErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	)
}

export default QueryBoundary
